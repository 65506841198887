import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController, ModalController } from '@ionic/angular';
import { BACKDROP } from '@ionic/core/dist/types/utils/overlays';
import { Conta, Login, LoginService } from 'src/app/services/login.service';
import { PerfilAcesso, PerfilService } from 'src/app/services/perfil.service';
import { LogoutModalPage } from './logout-modal/logout-modal.page'

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  conta_logada: Conta = {} as Conta;
  acessoPainel: PerfilAcesso = undefined as PerfilAcesso;
  pathListagem: PerfilAcesso[] = [];
  pathCadastro: PerfilAcesso[] = [];
  pathAuditoria: PerfilAcesso[] = [];
  pathFiscalizacao: PerfilAcesso[] = [];
  pathRelatorio: PerfilAcesso[] = [];
  pathConfiguracao: PerfilAcesso[] = [];

  showMenuFiscalizacao = false;
  showMenuAuditoria = false;
  showMenuCadastro = false;
  showMenuRelatorio = false;
  showMenuConfiguracao = false;

  interval;
  timeOutLogout;

  constructor(
    private router: Router,
    private menuController: MenuController,
    private loginService: LoginService,
    private perfilService: PerfilService,
    private modalController: ModalController
  ) { }

  ngOnInit() {
    this.carregaMenu();
    this.startIntervalCarregaMenu();
  }

  private startIntervalCarregaMenu() {
    this.interval = setInterval(
      () => {
        if (this.conta_logada.nome == undefined) {
          this.carregaMenu();
        }
      }, 1000
    );
  }

  private carregaMenu() {
    this.menuController.enable(false, 'menu_principal');
    this.loginService.getLogin().then(
      async (login: Login) => {
        if (login !== null) {
          this.conta_logada = login.conta;
          this.perfilService.getPerfilAcesso(login.conta.perfil_id).then(
            (perfilAcesso: PerfilAcesso[]) => {
              this.pathListagem = perfilAcesso.filter(
                (el: PerfilAcesso) => {
                  return (el.modulo_path === 'Listagem' && el.possui_acesso)
                }
              );
              this.pathCadastro = perfilAcesso.filter(
                (el: PerfilAcesso) => {
                  return (el.modulo_path === 'Cadastro' && el.possui_acesso)
                }
              );
              this.pathAuditoria = perfilAcesso.filter(
                (el: PerfilAcesso) => {
                  return (el.modulo_path === 'Auditoria' && el.possui_acesso)
                }
              );
              this.pathFiscalizacao = perfilAcesso.filter(
                (el: PerfilAcesso) => {
                  return (el.modulo_path === 'Fiscalização' && el.possui_acesso)
                }
              );
              this.acessoPainel = perfilAcesso.find(
                (el: PerfilAcesso) => {
                  return (el.modulo_path === 'Painel' && el.possui_acesso)
                }
              );
              this.pathRelatorio = perfilAcesso.filter(
                (el: PerfilAcesso) => {
                  return (el.modulo_path === 'Relatório' && el.possui_acesso)
                }
              );
              this.pathConfiguracao = perfilAcesso.filter(
                (el: PerfilAcesso) => {
                  return (el.modulo_path === 'Configuração' && el.possui_acesso)
                }
              );
              
              clearInterval(this.interval);
            }
          );
          // this.iniciaTempoLogout();
        }
      }
    );
  }

  private iniciaTempoLogout() {
    // if (this.conta_logada.nome != undefined) {
    //   let i = 0;
    //   this.timeOutLogout = setInterval(
    //     () => {
    //       this.showModalLogoff();
    //       i++;
    //     }, 600000
    //   );
    // }
  }

  private restartTempoLogout() {
    // clearTimeout(this.timeOutLogout);
    // this.iniciaTempoLogout();
  }

  async showModalLogoff() {
    clearTimeout(this.timeOutLogout);
    const modal = await this.modalController.create({
      component: LogoutModalPage,
      cssClass: "modal-logout",
      componentProps: {
        contaLogada: this.conta_logada
      },
    });

    modal.onDidDismiss().then(async (params) => {
      if (params !== null) {
        if (params.data != undefined) {
          if (params.data.efetua_logout) {
            this.destroyLogin();
            this.router.navigate(["login"])
          } else {
            this.restartTempoLogout();
          }
        } else if (params.role == 'backdrop') {
          this.restartTempoLogout();
        }
      }
    });
    return await modal.present();
  }

  setShowMenuConfiguracao(){
    this.showMenuConfiguracao = !this.showMenuConfiguracao;    
  }

  setShowMenuRelatorio(){
    this.showMenuRelatorio = !this.showMenuRelatorio;    
  }

  setShowMenuFiscalizacao() {
    this.showMenuFiscalizacao = !this.showMenuFiscalizacao;
    this.showMenuCadastro = false;
  }


  setShowMenuAuditoria() {
    this.showMenuAuditoria = !this.showMenuAuditoria;
    this.showMenuCadastro = false;
  }

  setShowMenuCadastro() {
    this.showMenuCadastro = !this.showMenuCadastro;
    this.showMenuAuditoria = false;
  }

  pushPage(rota: String) {
    this.restartTempoLogout();
    this.router.navigate([rota], { skipLocationChange: true })
    this.menuController.close();
    this.showMenuAuditoria = false;
    this.showMenuCadastro = false;
  }

  destroyLogin() {
    this.menuController.enable(false, 'menu_principal');
    this.menuController.close('menu_principal');
    this.conta_logada.nome = undefined;
    this.startIntervalCarregaMenu();
    this.loginService.destroyLogin();
  }

  pushPainelUsuarioLogado() {
    this.menuController.close('menu_principal');
    this.router.navigate(["usuario-painel"], {
      queryParams: { id: this.conta_logada.usuario_id },
      skipLocationChange: true
    });
  }

}
