import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Login, LoginService } from 'src/app/services/login.service';
import { PerfilAcesso, PerfilService } from 'src/app/services/perfil.service';
import { OverlayService } from '../services/overlay.service';

@Injectable({
  providedIn: 'root'
})
export class PermissaoAcessoGuard implements CanActivate {
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkPermissaoAcesso(route);
  }

  constructor(
    private perfilService: PerfilService,
    private loginService: LoginService,
    private router: Router,
    private overlayService: OverlayService
  ) {

  }

  private async checkPermissaoAcesso(route: ActivatedRouteSnapshot): Promise<boolean> {
    return new Promise((resolve) => {
      this.loginService.getLogin().then(
        (login: Login) => {
          if (login != null) {
            this.perfilService.getPerfilAcesso(login.conta.perfil_id).then(
              (perfilAcesso: PerfilAcesso[]) => {
                let acesso: PerfilAcesso;
                let rota: string = "";
                if (route.parent.routeConfig != null) {
                  rota = route.parent.routeConfig.path;
                } else {
                  rota = route.routeConfig.path;
                }
                acesso = perfilAcesso.find(
                  (el: PerfilAcesso) => {
                    return el.path == route.routeConfig.path
                  }
                );
                if (acesso != null && !acesso.possui_acesso) {
                  this.router.navigate(['/home']);
                  this.overlayService.toast({
                    message: "Você não possui permissão para acessar essa tela.",
                    color: 'danger'
                  });
                  resolve(false);
                } else {
                  resolve(true);
                }
              }
            )
          } else {
            resolve(true);
          }
        }
      )
    });
  }

}
