import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { ApiService } from '../api/api.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { OverlayService } from '../core/services/overlay.service';

export interface Perfil {
  id: number;
  nome: string;
  indauditorinterno: boolean;
  indauditorexterno: boolean;
  indguiaauditoria: boolean;
}

export interface PerfilAcesso {
  id: number;
  path: string;
  possui_acesso: boolean;
  nome_path: string;
  modulo_path: string
}

const TABELA = "perfil"

@Injectable({
  providedIn: 'root'
})
export class PerfilService {

  loading: any;

  constructor(
    private storage: Storage,
    private apiService: ApiService,
    private router: Router,
    private overlayService: OverlayService
  ) { }

  getPerfilById(id) {
    return this.storage.get(TABELA).then(
      (perfis) => {
        if (perfis != null) {
          return perfis.find(
            (perfil) => perfil.id === parseInt(id)
          )
        }
      }
    );
  }

  getAllPerfil(): Promise<Perfil[]> {
    return this.storage.get(TABELA);
  }


  async getPerfilAcesso(perfil_id: number): Promise<PerfilAcesso[]> {
    // const loading = await this.overlayService.loading(
    //   {
    //     message: "Obtendo permissões..."
    //   }
    // );
    return new Promise((resolve, reject) => {
      this.apiService.sendData(
        {
          id: perfil_id,
        },
        "get_permissao_acesso_perfil"
      ).subscribe(
        (perfilAcesso: PerfilAcesso[]) => {
          // loading.dismiss();
          resolve(perfilAcesso);
        },
        (erro) => {
          // loading.dismiss();
          let msg = '';
          if (erro.error != undefined) {
            msg = erro.error.message
          } else {
            msg = erro.message
          }
          this.showMensage("Erro ao obter as permissões : " + msg, 'danger');
          reject();
        }
      )
    })
  }

  async setPerfilAcesso(perfilAcesso: PerfilAcesso[], perfilId: number): Promise<void> {
    const loading = await this.overlayService.loading(
      {
        message: "Salvando permissões..."
      }
    );
    return new Promise((resolve, reject) => {
      this.apiService.sendData(
        {
          id: perfilId,
          acesso: perfilAcesso
        },
        "set_permissao_acesso_perfil"
      ).subscribe(
        () => {
          loading.dismiss();
          this.showMensage("Permissões salvas com sucesso!", 'success');
          resolve();
        },
        (erro) => {
          loading.dismiss();
          let msg = '';
          if (erro.error != undefined) {
            msg = erro.error.message
          } else {
            msg = erro.message
          }
          this.showMensage("Erro ao obter as permissões : " + msg, 'danger');
          reject();
        }
      )
    })
  }

  usuarioAdminstrador(usuario_id: number): Observable<any> {
    return this.apiService.sendData(
      {
        usuario_id: usuario_id,
      },
      "verifica_usuario_administrador"
    )
  }

  usuarioAuditorExterno(usuario_id: number): Observable<any> {
    return this.apiService.sendData(
      {
        usuario_id: usuario_id,
      },
      "verifica_usuario_auditor_externo"
    )
  }

  usuarioAuditorInterno(usuario_id: number): Observable<any> {
    return this.apiService.sendData(
      {
        usuario_id: usuario_id,
      },
      "verifica_usuario_auditor_interno"
    )
  }

  async sincronizaPerfil(): Promise<void> {
    const loading = await this.overlayService.loading({ message: "Sincronizando perfil..." });
    try {
      this.apiService.syncData('sincroniza_perfil').subscribe(
        (retorno) => {
          if (retorno.length > 0) {
            this.addPerfilSync(retorno);
          }
        },
        (erro) => {
          let msg = '';
          if (erro.error != undefined) {
            msg = erro.error.message
          } else {
            msg = erro.message
          }
          this.showMensage("Erro ao sincronizar : " + msg, 'danger');
        }
      );
    }
    finally {
      loading.dismiss();
    }
  }

  envioDadosPerfil(perfil: Perfil): any {
    this.apiService.sendData(
      perfil,
      "set_perfil"
    ).subscribe(
      (retorno) => {
        this.showMensage("Registro inserido/alterado com sucesso.", 'success');
        this.setRetornoApi(perfil, retorno);
        this.router.navigate(['perfil'], { skipLocationChange: true });
      },
      (erro) => {
        let msg = '';
        if (erro.error != undefined) {
          msg = erro.error.message
        } else {
          msg = erro.message
        }
        this.showMensage(msg, 'danger');
        return false;
      }
    );
  }

  private setRetornoApi(perfil: Perfil, retorno) {
    if (perfil.id != null && perfil.id != undefined) {
      this.updatePerfil(perfil);
    }
    else {
      perfil.id = retorno.id;
      this.addPerfil(perfil);
    }

  }

  private addPerfil(perfil: Perfil): Promise<any> {
    return this.storage.get(TABELA).then(
      (perfis: Perfil[]) => {
        if (perfis) {
          perfis.push(perfil);
          this.storage.set(TABELA, perfis);
        } else {
          this.storage.set(TABELA, [perfil]);
        }
      }
    );
  }

  private updatePerfil(perfil: Perfil): Promise<any> {
    return this.storage.get(TABELA).then(
      (perfis: Perfil[]) => {
        if (!perfis || perfis.length === 0) {
          return null;
        }

        let newPerfis: Perfil[] = [];

        for (let i of perfis) {
          if (i.id === perfil.id) {
            newPerfis.push(perfil);
          } else {
            newPerfis.push(i);
          }
        }

        return this.storage.set(TABELA, newPerfis);
      }
    );
  }

  async addPerfilSync(perfis: Perfil[]): Promise<any> {
    return this.storage.set(TABELA, perfis);
  }

  private async showMensage(msg, color) {
    this.overlayService.toast(
      {
        message: msg,
        color: color
      }
    )
  }
}
